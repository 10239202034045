<template>
  <div class="page-layout">
    <header>
      <NavBar
        v-if="navbar"
        icon="fa fa-box-open"
        :header="
          !pending && center.name ? center.name : 'Application Inventaire'
        "
      ></NavBar>
      <div class="p-3">
        <slot name="header"></slot>
      </div>
    </header>
    <!--    <main :style="`height: calc(calc(calc(var(&#45;&#45;vh, 1vh) * 100)) - ${height}px); padding: ${padding}`">-->
    <main :class="padding ? 'p-3' : ''">
      <div class="container">
        <slot name="default"></slot>
      </div>
    </main>
    <footer v-if="$slots.footer">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
import { computed } from "vue";
import useDatabase from "../composables/useDatabase";
import { useRoute } from "vue-router";

export default {
  props: {
    padding: {
      type: Boolean,
      default: false,
    },
    navbar: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { slots }) {
    const height = computed(() => {
      let h = 0;
      if (slots.header) h += 64;
      if (slots.footer) h += 48;
      return h;
    });

    const route = useRoute();
    const { centerId } = route.params;
    const { doc: center, pending } = useDatabase("centers").getOne(centerId);

    return { height, center, pending };
  },
};
</script>

<style lang="scss">
.page-layout {
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-content: space-between;
  height: calc(calc(var(--vh, 1vh) * 100));
  header {
    position: sticky;
    top: 0;
    //z-index: 2;
    border-bottom: solid 1px #f0f0f0;
  }
  main {
    //z-index: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    min-width: 100vw;
    //background-color: #faf9f8;
    position: relative;
    .links {
      position: absolute;
      bottom: 1rem;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
  footer {
    height: 48px;
    border-top: solid 1px rgba($secondary-light, 0.1);
  }
}
</style>
