import { createRouter, createWebHistory } from "vue-router";
import { projectAuth } from "../firebase/config";
import useMercurial from "../composables/useMercurial";

const { setMercurial } = useMercurial();

// NavGuard: route guard
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  if (!user && !to.meta.anonymous) {
    next({ name: "Login" });
  } else {
    next();
  }
};

// NavGuard: store center data
const storeCenterData = async (to, from, next) => {
  if (!to.params.centerId) {
    next();
  } else {
    await setMercurial(to.params.centerId);
    next();
  }
};

// const requireCenter = async (to, from, next) => {
//   let token = await projectAuth.currentUser.getIdTokenResult();
//   if (
//     (token.claims.roles && token.claims.roles.admin) ||
//     token.claims.centers[to.params.centerId]
//   ) {
//     next();
//   } else {
//     next({ name: "Home" });
//   }
// };

const routes = [
  {
    path: "/auth",
    redirect: { name: "Login" },
    component: () => import("../layouts/Auth"),
    meta: { anonymous: true },
    children: [
      {
        path: "login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "Login" */ "../views/auth/Login.vue"),
      },
    ],
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: { anonymous: true },
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/auth/ForgotPassword"
      ),
  },
  {
    path: "/register",
    name: "Register",
    meta: { anonymous: true },
    props: true,
    component: () =>
      import(/* webpackChunkName: "Register" */ "../views/auth/Register"),
    redirect: "/register/1",
    children: [
      {
        path: "1",
        component: () => import("../views/auth/register/page1"),
      },
      {
        path: "2",
        component: () => import("../views/auth/register/page2"),
      },
      {
        path: "3",
        component: () => import("../views/auth/register/page3"),
      },
      {
        path: "4",
        component: () => import("../views/auth/register/page4"),
      },
    ],
  },
  {
    path: "/",
    name: "Home",
    // beforeEnter: activeCenter,
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home"),
  },
  {
    path: "/:centerId/boards",
    // beforeEnter: requireCenter,
    name: "Boards",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Boards" */ "../views/boards/BoardList"),
  },
  {
    path: "/:centerId/boards/:boardId",
    name: "BoardDetails",
    component: () => import("../views/boards/BoardDetails"),
    // beforeEnter: requireCenter,
  },
  {
    path: "/:centerId/zones",
    name: "ZonesList",
    component: () => import("../views/centers/ZonesList"),
  },
  {
    path: "/:centerId/zones/:zoneId/areas",
    name: "AreasList",
    component: () => import("../views/centers/AreasList"),
    children: [
      {
        path: ":areaId",
        name: "AreaShow",
        component: () => import("../views/centers/_AreaRows"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(requireAuth);
router.beforeEach(storeCenterData);

export default router;
