<template>
  <div class="base-btn-set" :style="rtl ? 'flex-direction: row-reverse' : ''">
    <label>
      <span v-if="!$slots.default">{{ label }}</span>
      <slot v-else></slot>
    </label>
    <Button
      class="p-button-rounded"
      :class="[`p-button-${color}`, `${text ? 'p-button-text' : ''}`]"
      :icon="icon"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    icon: String,
    color: String,
    text: Boolean,
    rtl: Boolean,
    disabled: Boolean,
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.base-btn-set {
  display: flex;
  align-items: center;
  label {
    font-size: 0.75rem;
    margin: 0 0.25rem;
    color: $secondary !important;
  }
  button {
    transform: scale(0.9);
  }
}
</style>
